import PropTypes from 'prop-types';
import EmptyMessage from './empty-message';
import React, { useState } from 'react';
import { List, ListItem, ListSubheader, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckmarkIcon from '@material-ui/icons/Done';
import Badge from '@material-ui/core/Badge';

const styles = theme => ({
  paper: {
    marginBottom: theme.spacing.unit * 2
  },
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    flexBasis: '80%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
});

const renderList = props => {
  const { items, renderCustomItem, renderAvatar, renderDetails } = props;

  const renderListItems = () => {
    const lastIndex = items.length - 1;
    return items.map((item, index) => {
      if (renderCustomItem) return renderCustomItem(item, index === lastIndex);
      return (
        <ListItem key={index}>
          {renderAvatar ? renderAvatar(item) : ''}
          {renderDetails ? renderDetails(item) : ''}
        </ListItem>
      );
    });
  };

  return <ul className="list">{renderListItems()}</ul>;
};

const renderExpansionDescription = props => {
  let pendingItems = [];
  for (let i = 0; i < props.items.length; i++) {
    if (!props.items[i].obtained && !props.items[i].cancelled)
      pendingItems.push(props.items[i]);
  }
  const count = pendingItems.length;
  return count === 0 ? (
    <Typography>
      <CheckmarkIcon style={{ color: 'green' }} />
    </Typography>
  ) : (
    <Typography className={props.classes.secondaryHeading}>
      <Badge badgeContent={count} color="secondary" />
    </Typography>
  );
};

const Panel = props => {
  const [isOpen, setIsOpen] = useState(props.defaultExpanded);
  const { emptyMessage, items, expandable } = props;
  if (items.length === 0) return <EmptyMessage message={emptyMessage} />;
  if (!expandable) {
    return (
      <Paper className={props.classes.paper}>
        <List
          subheader={
            <ListSubheader color="primary" disableSticky={true}>
              {props.title}
            </ListSubheader>
          }>
          {renderList(props)}
        </List>
      </Paper>
    );
  }
  return (
    <ExpansionPanel
      style={{ padding: 0 }}
      defaultExpanded={props.defaultExpanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={() => setIsOpen(!isOpen)}>
        <Typography className={props.classes.heading}>{props.title}</Typography>
        {!isOpen && renderExpansionDescription(props)}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ padding: 0 }}>
        <List style={{ width: '100%' }}>{renderList(props)}</List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

Panel.propTypes = {
  emptyMessage: PropTypes.string
};

Panel.defaultProps = {
  emptyMessage: 'No items found'
};

export default withStyles(styles)(Panel);
