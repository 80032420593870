import React from 'react';
import { auth } from '../../base';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import Confetti from 'react-dom-confetti';
import Hidden from '@material-ui/core/Hidden';
import AppBarMenuIcon from '../shared/appbar-menu-icon';

const drawerWidth = 240;
const styles = theme => ({
  appBar: {
    position: 'fixed',
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${ drawerWidth }px)`
    }
  },
  flex: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRigiht: 20
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

class DashboardNav extends React.Component {
  state = { anchorEl: null }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  handleProfile = () => {
    this.setState({ anchorEl: null }, () => {
      this.props.history.push('/dashboard/user');
    });
  }

  handleLogout = (ev) => {
    ev.preventDefault();
    auth.signOut().then(() => {
      this.props.history.push('/');
    }); 
  }

  handleMenu = (ev) => {
    this.setState({ anchorEl: ev.currentTarget });
  }

  renderProgress() {
    const { isShopping, shoppingProgressValue } = this.props;
    if (!isShopping) return;
    if ((!Boolean(shoppingProgressValue) && shoppingProgressValue !== 0) || shoppingProgressValue < 0) return;
    return (
      <React.Fragment>
        <LinearProgress variant='determinate' value={ shoppingProgressValue } color='secondary' />
        <Hidden mdUp>
          <Confetti
            active={ shoppingProgressValue === 100 }
            config={{
              elementCount: 250,
              decay: 0.8,
              startVelocity: 80,
              spread: 150,
              angle: 315
            }}
          />
        </Hidden>
        <Hidden smDown>
          <Confetti
            active={ shoppingProgressValue === 100 }
            config={{
              elementCount: 500,
              decay: 0.85,
              startVelocity: 80,
              spread: 200,
              angle: 0 
            }}
          />
        </Hidden>
      </React.Fragment>
    );
  }

  render() {
    const { classes, handleDrawerToggle, user } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <AppBar className={ classes.appBar }>
        <Toolbar>
          <AppBarMenuIcon
            color='inherit'
            handleDrawerToggle={ handleDrawerToggle }
          />
          <Typography
            className={ classes.flex }
            variant='title'
            color='inherit'
          >
            { this.props.title }
          </Typography>
          <div>
            <IconButton onClick={ this.handleMenu }>
              <Avatar alt={ user.displayName } src={ user.photoURL } />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={ anchorEl }
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={ open }
              onClose={ this.handleClose }
            >
              <MenuItem onClick={ this.handleProfile }>Profile</MenuItem>
              <MenuItem onClick={ this.handleLogout }>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
        { this.renderProgress() }
      </AppBar>
    );
  }
}

export default withStyles(styles)(DashboardNav);