import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  }
});

class PrimaryButton extends React.Component {
  render() {
    const { classes, handleClick, label, variant } = this.props;
    return (
      <Button
        className={classes.root}
        color="secondary"
        fullWidth
        onClick={handleClick}
        variant={variant}
        {...this.props}>
        {label}
      </Button>
    );
  }
}

PrimaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  variant: PropTypes.string
};

PrimaryButton.defaultProps = {
  variant: 'outlined'
};

export default withStyles(styles)(PrimaryButton);
