import React from 'react';
import { listenToRequestsCollection } from '../../helpers/lists-refs';
import Badge from '@material-ui/core/Badge';

class PendingRequestBadge extends React.Component {
  state = { pendingCount: 0, loading: true };

  componentDidMount() {
    listenToRequestsCollection(this.props.listId, snapshot => {
      let pendingCount = 0;
      snapshot.forEach(reqDoc => {
        let { cancelled, obtained, archived } = reqDoc.data();
        if (!archived && !(cancelled || obtained)) pendingCount += 1;
      });
      this.setState({ pendingCount, loading: false });
    });
  }

  render() {
    const { loading, pendingCount } = this.state;
    if (loading) return '...';
    return (
      <Badge
        color={pendingCount > 0 ? 'secondary' : 'default'}
        badgeContent={pendingCount}
      />
    );
  }
}

export default PendingRequestBadge;
