import React from 'react';
import ListForm from '../stores/form';
import { updateListDoc } from '../../helpers/lists-refs';
import { getFirebaseTimestamp } from '../../../base';
import { Button, Typography } from '@material-ui/core';

class ListsEdit extends React.Component {
  handleDelete = (ev) => {
    ev.preventDefault();
    this.props.setSnackbarContent('TODO: Not yet implemented!');
  }

  handleSubmit = (listData) => {
    let list = { ...listData };
    Object.assign(list, { updatedAt: getFirebaseTimestamp() });

    const { history, match, setSnackbarContent } = this.props;
    const { listId } = match.params;
    updateListDoc(listId, list).then(() => {
      setSnackbarContent('Successfully updated list');
      history.push(`/dashboard/lists/${ listId }`);
    });
  }

  render() {
    const { list } = this.props;
    return (
      <React.Fragment>
        <Typography variant='headline'>Edit List</Typography>
        <ListForm
          name={ list.name }
          handleSubmit={ this.handleSubmit }
          submitLabel='Update List'
        />

        <Typography>Delete List</Typography>
        <Button color='secondary' onClick={ this.handleDelete }>Delete List</Button>
      </React.Fragment>
    );
  }
}

export default ListsEdit;