import React from 'react';
import RequestsFormFields from './form-fields';
import { addRequestToList } from '../../helpers/lists-refs';
import { getFirebaseTimestamp } from '../../../base';
import Typography from '@material-ui/core/Typography';
import FormContainer from '../../shared/form-container';

const handleSubmit = (props, request, resetFormThen) => {
  const { uid } = props.user;
  const { listId } = props.match.params;

  let req = { ...request };

  Object.assign(req, {
    creatorId: uid,
    createdAt: getFirebaseTimestamp(),
    obtained: false,
    obtainedAt: null,
    obtainerId: null,
    cancelled: false,
    cancelledAt: null,
    cancellerId: null,
    archived: false
  });

  addRequestToList(req, listId, () => {
    resetFormThen(() => {
      props.setSnackbarContent(`Got it! Successfully added ${req.name}`);
      props.history.push(`/dashboard/lists/${listId}`);
    });
  });
};

const RequestsNew = props => (
  <FormContainer>
    <Typography variant="display1">New Request</Typography>
    <RequestsFormFields
      user={props.user}
      match={props.match}
      submitLabel="Create Request"
      handleRequestAction={handleSubmit.bind(this, props)}
    />
  </FormContainer>
);

export default RequestsNew;
