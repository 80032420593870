import { Loading } from '../../shared/loading';
import React from 'react';
import {
  deleteStoreDoc,
  updateStoreDoc,
  updateListDoc,
  getStoreDoc,
  getListDoc
} from '../../helpers/lists-refs';
import Typography from '@material-ui/core/Typography';
import Form from './form';
import Button from '@material-ui/core/Button';
import FormContainer from '../../shared/form-container';

class StoresEdit extends React.Component {
  state = { store: null, loading: true, defaultStoreId: undefined };

  handleRemove = ev => {
    ev.preventDefault();
    const { listId, storeId } = this.props.match.params;
    deleteStoreDoc(listId, storeId).then(() => {
      this.props.setSnackbarContent('Store has been deleted');
      this.props.history.push(`/dashboard/lists/${listId}`);
    });
  };

  handleSubmit = ({ name, isDefault }) => {
    const { listId } = this.props.match.params;
    const { id } = this.state.store;
    const storeData = { name };
    updateStoreDoc(listId, id, storeData).then(doc => {
      if (isDefault) {
        updateListDoc(listId, { defaultStoreId: id });
      }
      this.props.setSnackbarContent('Successfully updated store');
      this.props.history.push(`/dashboard/lists/${listId}/stores`);
    });
  };

  componentDidMount() {
    const { listId, storeId } = this.props.match.params;

    getStoreDoc(listId, storeId).then(doc => {
      let store = doc.data();
      store.id = doc.id;
      getListDoc(listId).then(doc => {
        this.setState({
          store: store,
          defaultStoreId: doc.data().defaultStoreId,
          loading: false
        });
      });
    });
  }

  render() {
    const { loading } = this.state;
    if (loading) return <Loading />;
    return (
      <React.Fragment>
        <FormContainer>
          <Typography variant="display1">Edit Store</Typography>
          <Form
            name={this.state.store.name}
            isDefault={this.state.defaultStoreId === this.state.store.id}
            submitLabel="Update Store"
            handleSubmit={this.handleSubmit}
          />
        </FormContainer>
        <FormContainer>
          <Typography variant="display1">Delete Store</Typography>
          <Button color="secondary" fullWidth onClick={this.handleRemove}>
            Delete Store
          </Button>
        </FormContainer>
      </React.Fragment>
    );
  }
}

export default StoresEdit;
