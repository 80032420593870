import _ from 'lodash';
import { Loading } from '../../shared/loading';
import React from 'react';
import RequestItem from './item';
import Panel from '../../shared/panel';
import ShoppingList from './shopping-list';
import { Link } from 'react-router-dom';
import { listenToStoresCollection } from '../../helpers/lists-refs';
import Filters from './filters';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const TABS = [
  {
    label: 'Pending',
    color: 'secondary',
    emptyMessage: 'All caught up! There are no pending requests.'
  },
  {
    label: 'Obtained',
    color: 'default',
    emptyMessage: 'No requests have been obtained'
  },
  {
    label: 'Cancelled',
    color: 'default',
    emptyMessage: 'No requests have been cancelled'
  },
  {
    label: 'My Requests',
    color: 'default',
    emptyMessage: 'You have no pending or recently completed requests'
  }
];

const styles = theme => ({
  headline: {
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18
    }
  }
});

const SafeTravels = styled.div`
  background: ${props => (props.doneShopping ? green[100] : blue[100])};
  padding: 10px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 16px;
`;

class RequestsList extends React.Component {
  state = { loading: true, stores: [], selectedTab: 0 };

  filteredRequests = specifiedTab => {
    const { requests } = this.props;
    const { selectedTab } = this.state;
    const { uid } = this.props.user;

    let tabValue = specifiedTab !== undefined ? specifiedTab : selectedTab;

    let filteredRequests = [];
    switch (tabValue) {
      case 0:
        filteredRequests = _.filter(
          requests,
          req => !(req.obtained || req.cancelled)
        );
        break;
      case 1:
        filteredRequests = _.filter(requests, req => req.obtained);
        break;
      case 2:
        filteredRequests = _.filter(requests, req => req.cancelled);
        break;
      case 3:
        filteredRequests = _.filter(requests, req => req.creatorId === uid);
        break;
      default:
        filteredRequests = _.filter(
          requests,
          req => !(req.obtained || req.cancelled)
        );
        break;
    }
    return filteredRequests;
  };

  onStoresUpdate = snapshot => {
    let stores = [];
    snapshot.forEach(storeDoc => {
      let store = storeDoc.data();
      store.id = storeDoc.id;
      stores.push(store);
    });
    this.setState({ stores, loading: false });
  };

  componentDidMount() {
    const { listId } = this.props.match.params;
    this.storesListener = listenToStoresCollection(listId, this.onStoresUpdate);
  }

  componentWillUnmount() {
    this.storesListener();
  }

  generateStoreMap() {
    const { stores } = this.state;
    let storeMap = {};
    stores.forEach(store => {
      storeMap[store.id] = store.name;
    });
    return storeMap;
  }

  handleTabChange = (_, value) => {
    this.setState({ selectedTab: value });
  };

  handleSelectChange = ev => {
    this.setState({ selectedTab: parseInt(ev.target.value, 10) });
  };

  renderCustomItem = (request, isLastIndex) => {
    const { match, history, isShopping, user } = this.props;
    return (
      <RequestItem
        history={history}
        isLastIndex={isLastIndex}
        request={request}
        storesMap={this.generateStoreMap()}
        key={request.id}
        match={match}
        user={user}
        isShopping={isShopping}
      />
    );
  };

  renderAddRequest = () => {
    if (this.props.isShopping) return;
    const { listId } = this.props.match.params;
    return (
      <Link className="right" to={`/dashboard/lists/${listId}/requests/new`}>
        Add Request
      </Link>
    );
  };

  render() {
    const { loading, selectedTab, stores } = this.state;
    const {
      classes,
      shoppingProgressValue,
      isShopping,
      history,
      match,
      requests
    } = this.props;
    if (loading) return <Loading />;
    if (!isShopping) {
      const items = this.filteredRequests();
      return (
        <React.Fragment>
          <Filters
            filteredRequests={this.filteredRequests}
            handleSelectChange={this.handleSelectChange}
            handleTabChange={this.handleTabChange}
            selectedTab={selectedTab}
            tabs={TABS}
          />
          <Panel
            emptyMessage={TABS[selectedTab].emptyMessage}
            items={items}
            renderCustomItem={this.renderCustomItem}
          />
        </React.Fragment>
      );
    }
    const doneShopping = shoppingProgressValue === 100;
    const renderEncouragementMessage = () => {
      let minute = parseInt(new Date().getSeconds() / 3); // TODO fix the double render issue when state changes?
      const messages = [
        'Happy shopping! Drive safe. 🚦',
        "Let's do this 😎",
        'Show them how shopping is done! 💪🏻',
        "It's grocery time! 👨🏻‍🌾",
        'Grocery stores, here we come! 🚗'
        //'"Thanks for feeding us!" -😸😺🐶👨🏻‍💻👸🏻💑👨🏻‍⚕️'
      ];
      const index = parseInt(minute % messages.length);
      return messages[index];
    };
    const renderSuccessMessage = () => {
      let minute = parseInt(new Date().getSeconds() / 3); // TODO fix the double render issue when state changes?
      const messages = [
        'All done! Head home 🚗',
        'Woohoo! Rest and relaxation 🌴',
        'You did it! Nice work 👍🏼',
        'Thanks! You are all done! 🤩',
        'Another successful shopping excursion! 🎉',
        'You are the grocery champion! 🏆'
      ];
      const index = parseInt(minute % messages.length);
      return messages[index];
    };
    return (
      <ShoppingList
        stores={stores}
        storesMap={this.generateStoreMap()}
        requests={requests}
        renderCustomItem={this.renderCustomItem}
        history={history}
        match={match}
        isShopping={isShopping}
      />
    );
  }
}

export default withStyles(styles)(RequestsList);
