import { Link } from 'react-router-dom';
import React from 'react';
import Panel from '../../shared/panel';
import { getListDoc, listenToStoresCollection } from '../../helpers/lists-refs';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import PrimaryButton from '../../shared/primary-button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Star from '@material-ui/icons/Star';

class StoresList extends React.Component {
  state = { stores: [], loading: true, defaultStoreId: undefined };

  componentDidMount() {
    const { listId } = this.props.match.params;
    this.list = getListDoc(listId).then(doc => {
      this.setState({ defaultStoreId: doc.data().defaultStoreId });
    });
    this.removeListener = listenToStoresCollection(listId, this.handleStores);
  }

  componentWillUnmount() {
    this.removeListener();
  }

  handleStores = snapshot => {
    let stores = [];
    snapshot.forEach(storeDoc => {
      let store = storeDoc.data();
      store.id = storeDoc.id;
      stores.push(store);
    });
    this.setState({ stores, loading: false });
  };

  renderCustomItem = ({ id, name }, isLastIndex) => {
    const { listId } = this.props.match.params;
    return (
      <React.Fragment>
        <ListItem>
          <ListItemIcon>
            <Star
              style={{ opacity: this.state.defaultStoreId === id ? 1 : 0 }}
            />
          </ListItemIcon>
          <ListItemText primary={name} />
          <ListItemSecondaryAction>
            <IconButton
              component={Link}
              to={`/dashboard/lists/${listId}/stores/${id}/edit`}>
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {isLastIndex ? '' : <Divider />}
      </React.Fragment>
    );
  };

  render() {
    const { loading, stores } = this.state;
    const { listId } = this.props.match.params;
    if (loading) return <CircularProgress />;

    return (
      <React.Fragment>
        <PrimaryButton
          component={Link}
          to={`/dashboard/lists/${listId}/stores/new`}
          label="Add Store"
        />
        <Panel
          emptyMessage="No stores found for this list"
          items={stores}
          title="Stores"
          renderCustomItem={this.renderCustomItem}
        />
      </React.Fragment>
    );
  }
}

export default StoresList;
