import React from 'react';
import PrimaryButton from '../../shared/primary-button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

class StoresForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: props.name, isDefault: props.isDefault || false };
  }

  handleDefaultChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleChange = ev => {
    this.setState({ name: ev.target.value });
  };

  handleSubmit = ev => {
    ev.preventDefault();
    const { name, isDefault } = this.state;
    this.props.handleSubmit({ name, isDefault });
  };

  render() {
    const { name, isDefault } = this.state;
    const { submitLabel } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          autoFocus
          fullWidth
          autoComplete="off"
          margin="normal"
          label="Name"
          name="name"
          value={name}
          onChange={this.handleChange}
        />
        <FormControlLabel
          control={
            <Switch
              checked={isDefault}
              onChange={this.handleDefaultChange('isDefault')}
              value="isDefault"
              color="secondary"
            />
          }
          label="Set as default"
        />
        <PrimaryButton type="submit" variant="contained" label={submitLabel} />
      </form>
    );
  }
}

export default StoresForm;
