import React from 'react';
import orange from '@material-ui/core/colors/orange';
import { Link } from 'react-router-dom';
import {
  getUserDoc,
  updateRequest,
  listenToRequestDoc,
  listenToUserDoc
} from '../../helpers/lists-refs';
import { getFirebaseTimestamp } from '../../../base';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import { withStyles } from '@material-ui/core/styles';
import { getFormattedTimestamp } from '../../helpers/time';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import StoreDialogForm from '../../shared/store-dialog-form';

const styles = theme => ({
  item: {
    transition: '250ms background'
  },
  obtained: {
    background: green[100]
  },
  cancelled: {
    background: red[100]
  },
  pending: {
    background: '#FFF'
  },
  recurring: {
    background: blue[50]
  }
});

class RequestItem extends React.Component {
  state = {
    anchorEl: null,
    canceller: null,
    store: null,
    owner: null,
    ownerLoading: true,
    obtainer: null,
    menuOpen: false,
    storeSelectorOpen: false
  };

  storeRef = React.createRef();

  handleObtain = (reqId, obtained, ev) => {
    ev.preventDefault();
    const { listId } = this.props.match.params;
    const { uid } = this.props.user;
    const dateNow = getFirebaseTimestamp();

    updateRequest(listId, reqId, {
      obtained,
      obtainedAt: obtained ? dateNow : null,
      obtainerId: obtained ? uid : null,
      cancelled: false,
      cancellerId: null,
      cancelledAt: null,
      updatedAt: dateNow
    });
  };

  handleCancel = (reqId, cancelled) => {
    const { listId } = this.props.match.params;
    const { uid } = this.props.user;
    const dateNow = getFirebaseTimestamp();

    updateRequest(listId, reqId, {
      cancelled,
      cancelledAt: cancelled ? dateNow : null,
      cancellerId: cancelled ? uid : null,
      obtained: false,
      obtainerId: null,
      obtainedAt: null,
      updatedAt: dateNow
    });
  };

  handleCreator(userDoc) {
    const creatorId = userDoc.data().creatorId;
    getUserDoc(creatorId).then(userDoc => {
      let user = userDoc.data();
      user.id = userDoc.id;
      this.setState({ owner: user, ownerLoading: false });
    });
  }

  handleObtainer(userDoc) {
    const { cancellerId, obtainerId } = userDoc.data();
    if (obtainerId !== null) {
      this.obtainerListener = listenToUserDoc(obtainerId, userDoc => {
        let user = userDoc.data();
        user.id = userDoc.id;
        this.setState({ obtainer: user });
      });
    } else if (cancellerId !== null) {
      this.cancellerListener = listenToUserDoc(cancellerId, userDoc => {
        let user = userDoc.data();
        user.id = userDoc.id;
        this.setState({ canceller: user });
      });
    } else {
      this.setState({ obtainer: null });
    }
  }

  componentDidMount() {
    const { listId } = this.props.match.params;
    const { id } = this.props.request;

    this.requestListener = listenToRequestDoc(listId, id, userDoc => {
      this.handleCreator(userDoc);
      this.handleObtainer(userDoc);
    });
  }

  componentWillUnmount() {
    if (this.storeListener) this.storeListener();
    if (this.obtainerListener) this.obtainerListener();
    if (this.cancellerListener) this.cancellerListener();
    this.requestListener();
  }

  renderAvatar() {
    const { owner, ownerLoading } = this.state;
    if (ownerLoading) return;
    return <Avatar alt={owner.displayName} src={owner.photoURL} />;
  }

  renderNoSubstitute() {
    const { isShopping, request } = this.props;
    if (!isShopping || request.substituteAllowed) return '';
    return (
      <div style={{ display: 'block' }}>
        <Typography
          component="span"
          role="img"
          aria-hidden="true"
          style={{ fontSize: '18px', display: 'inline' }}>
          🙅‍♀️
        </Typography>
        <Typography
          component="span"
          style={{
            color: orange[900],
            textTransform: 'uppercase',
            display: 'inline',
            fontSize: '12px'
          }}>
          No sub, please.{' '}
        </Typography>
      </div>
    );
  }

  renderFurtherInstructions() {
    const { description } = this.props.request;
    if (description === '' || !Boolean(description)) return '';
    return `"${description}" `;
  }

  renderDescription() {
    const { isShopping, storesMap, request } = this.props;
    const { obtainedAt, cancelledAt, storeId } = request;
    const { canceller, obtainer } = this.state;
    if (isShopping) {
      const { owner, ownerLoading } = this.state;
      return ownerLoading ? (
        '...'
      ) : (
        <React.Fragment>
          {this.renderNoSubstitute()}
          {this.renderFurtherInstructions()} -{owner.displayName}
        </React.Fragment>
      );
    } else if (obtainer !== null) {
      return `Obtained by ${obtainer.displayName} (${getFormattedTimestamp(
        obtainedAt,
        true
      )})`;
    } else if (canceller !== null) {
      return `Cancelled by ${canceller.displayName} (${getFormattedTimestamp(
        cancelledAt,
        true
      )})`;
    } else {
      return storesMap[storeId];
    }
  }

  renderDetails() {
    const { loading } = this.state;
    const { request } = this.props;
    // console.log('request details: ', request);
    const { name } = request;
    if (loading) return;
    return <ListItemText primary={name} secondary={this.renderDescription()} />;
  }

  renderEditLink() {
    const { listId } = this.props.match.params;
    const { id, obtained, cancelled } = this.props.request;
    if (obtained || cancelled) return;
    return (
      <IconButton
        component={Link}
        to={`/dashboard/lists/${listId}/requests/${id}/edit`}>
        <EditIcon />
      </IconButton>
    );
  }

  renderObtainAction() {
    const { id, obtained } = this.props.request;
    return (
      <Checkbox
        checked={obtained}
        tabIndex={-1}
        onChange={this.handleObtain.bind(this, id, !obtained)}
      />
    );
  }

  renderCancelAction() {
    const { cancelled, id } = this.props.request;
    const options = [
      {
        label: cancelled ? 'Undo Cancel' : 'Cancel Request',
        handler: () => {
          this.handleCancel(id, !cancelled);
          this.setState({ menuOpen: false, anchorEl: null });
        }
      },
      {
        label: 'Change Store',
        handler: () => this.setState({ storeSelectorOpen: true })
      }
    ];
    // this.handleCancel.bind(this, id, !cancelled)
    return (
      <ListItemSecondaryAction>
        <IconButton
          aria-label="More"
          aria-owns={true ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={ev => {
            this.setState({ menuOpen: true, anchorEl: ev.currentTarget });
          }}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.menuOpen}
          onClose={() => {
            this.setState({ menuOpen: false, anchorEl: null });
          }}>
          {options.map(option => (
            <MenuItem key={option.label} onClick={option.handler}>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
        <StoreDialogForm
          handleClose={() => {
            this.setState({ storeSelectorOpen: false, menuOpen: false });
          }}
          open={this.state.storeSelectorOpen}
          request={this.props.request}
          listId={this.props.match.params.listId}
        />
      </ListItemSecondaryAction>
    );
  }

  render() {
    const {
      request,
      classes,
      isLastIndex,
      isShopping,
      primaryAction
    } = this.props;
    const { obtained, cancelled, isRecurring } = request;
    const status = obtained
      ? classes.obtained
      : cancelled
      ? classes.cancelled
      : classes.pending;
    const recurring =
      !obtained && !cancelled && isRecurring ? classes.recurring : null;
    return (
      <React.Fragment>
        <ListItem
          className={status + ' ' + classes.item + ' ' + recurring}
          button={!isShopping}
          onClick={
            isShopping
              ? () => {}
              : () => {
                  const { history, request, match } = this.props;
                  const { listId } = match.params;
                  history.push(
                    `/dashboard/lists/${listId}/requests/${request.id}/edit`
                  );
                }
          }>
          {isShopping && this.renderObtainAction()}
          {isShopping ? '' : this.renderAvatar()}
          {this.renderDetails()}
          {isShopping ? this.renderCancelAction() : this.renderEditLink()}
        </ListItem>
        {isLastIndex ? '' : <Divider />}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(RequestItem);
