import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import RequestList from '../requests/list';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ShoppingIcon from '@material-ui/icons/ShoppingCart';
import PrimaryButton from '../../shared/primary-button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit,
    position: 'fixed',
    right: '100px',
    bottom: '20px',
    zIndex: 9999,
    [theme.breakpoints.down('sm')]: {
      left: '50%',
      right: 'inherit',
      transform: 'translateX(-50%)'
    }
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  }
});

class ListsShow extends React.Component {
  renderShopperToggle() {
    const { isShopping } = this.props;
    return (
      <List>
        <ListItem>
          <ListItemIcon>
            <ShoppingIcon />
          </ListItemIcon>
          <ListItemText primary="Shopping Mode" />
          <ListItemSecondaryAction>
            <Switch
              onChange={this.props.handleShoppingToggle}
              checked={isShopping}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    );
  }

  render() {
    const { isShopping, match, history, user, requests, classes } = this.props;
    const { listId } = this.props.match.params;
    return (
      <React.Fragment>
        {this.renderShopperToggle()}
        {isShopping ? (
          ''
        ) : (
          <Fab
            variant="extended"
            color="secondary"
            aria-label="Delete"
            className={classes.fab}
            component={Link}
            to={`/dashboard/lists/${listId}/requests/new`}>
            <AddIcon className={classes.extendedIcon} />
            Add Request
          </Fab>
        )}
        <RequestList
          history={history}
          shoppingProgressValue={this.props.shoppingProgressValue}
          match={match}
          user={user}
          requests={requests}
          isShopping={isShopping}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ListsShow);
