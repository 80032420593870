import moment from 'moment';

export const timestampToMilliseconds = (timestamp) => {
  if (typeof timestamp !== 'object') return timestamp;
  return timestamp.seconds * 1000;
}

export const getFormattedTimestamp = (timestamp, timeOnly) => {
  const ms = timestampToMilliseconds(timestamp);
  const format = !timeOnly ? 'MMMM DD, YYYY h:mm a' : 'h:mm a';
  return moment(ms).format(format);
}