import { getFirebaseTimestamp } from '../../base';
import { addUserDoc, updateUserDoc } from '../helpers/lists-refs';

export const createUser = ({ uid, displayName, photoURL }, success, error) => {
  const timestamp = getFirebaseTimestamp();
  addUserDoc(uid, {
    displayName,
    photoURL,
    lastLoginAt: timestamp,
    createdAt: timestamp,
    updatedAt: timestamp
  })
    .then(res => success(res))
    .catch(err => error(err));
};

export const updateUser = ({ uid, providerData }, success, error) => {
  updateUserDoc(uid, {
    lastLoginAt: getFirebaseTimestamp()
  })
    .then(res => {
      success(res);
    })
    .catch(err => {
      error(err);
    });
};
