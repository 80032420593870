import _ from 'lodash';
import DashboardNav from '../shared/dashboard-nav';
import React from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';
import ListsEdit from '../pages/lists/edit';
import ListsShow from '../pages/lists/show';
import RequestsEdit from '../pages/requests/edit';
import RequestsNew from '../pages/requests/new';
import StoresNew from '../pages/stores/new';
import StoresEdit from '../pages/stores/edit';
import {
  listenToListDoc,
  listenToRequestsCollection
} from '../helpers/lists-refs';
import ListDrawer from '../shared/drawer';
import { withStyles } from '@material-ui/core/styles';
import ToolbarMixin from '../shared/toolbar-mixin';
import Divider from '@material-ui/core/Divider';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import RequestIcon from '@material-ui/icons/AssignmentLate';
import GroupIcon from '@material-ui/icons/Group';
import GearIcon from '@material-ui/icons/SettingsBackupRestore';
import StoreIcon from '@material-ui/icons/LocationOn';
import MemberIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import EditIcon from '@material-ui/icons/Edit';
import StoresList from '../pages/stores/list';
import MembersList from '../pages/users/list';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import ListSubheader from '@material-ui/core/ListSubheader';
import Badge from '@material-ui/core/Badge';
import grey from '@material-ui/core/colors/grey';
import CircularProgress from '@material-ui/core/CircularProgress';
import DrawerListItem from '../shared/drawer-list-item';
import WeeklyResetDialog from '../shared/weekly-reset-dialog';

const styles = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit,
    [theme.breakpoints.up('md')]: {
      marginLeft: 240,
      padding: theme.spacing.unit * 3
    }
  },
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  activeLink: {
    backgroundColor: grey[200]
  }
});

const renderTotalPercentComplete = requests => {
  let completedRequests = _.filter(requests, req => {
    return req.obtained || req.cancelled;
  });
  const value = Math.round((completedRequests.length / requests.length) * 100);
  return value;
};

class ListsContainer extends React.Component {
  state = {
    requests: [],
    isShopping: false,
    list: null,
    loading: true,
    mobileOpen: false,
    requestsLoading: true,
    pendingRequestCount: 0,
    shoppingProgressValue: 0,
    resetDialogOpen: false
  };

  componentDidMount() {
    const { listId } = this.props.match.params;
    this.listListener = listenToListDoc(listId, snapshot => {
      this.setState({ list: snapshot.data(), loading: false });
    });
    this.requestsListener = listenToRequestsCollection(listId, snapshot => {
      let pendingCount = 0;
      let requests = [];
      snapshot.forEach(requestDoc => {
        let { archived, cancelled, obtained } = requestDoc.data();
        if (!archived && !(cancelled || obtained)) pendingCount += 1;
        let request = requestDoc.data();
        request.id = requestDoc.id;
        if (!archived) requests.push(request);
      });
      this.setState({
        requests,
        pendingRequestCount: pendingCount,
        requestsLoading: false
      });
    });
  }

  componentWillUnmount() {
    this.listListener();
    if (this.requestsListener) this.requestsListener();
  }

  handleCloseDrawer = ev => {
    this.setState({ mobileOpen: false });
  };

  handleDrawerToggle = ev => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleShoppingToggle = () => {
    this.setState({ isShopping: !this.state.isShopping });
  };

  renderEditButton() {
    const { uid } = this.props.user;
    const { list } = this.state;
    if (!list) return;
    const { ownerId } = list;
    const { listId } = this.props.match.params;
    console.log('user: ', this.props.user);
    if (uid !== ownerId && uid !== 'pK2mgUxcnkUG0civPwUHU1JL7mh2') return;
    return (
      <React.Fragment>
        <List>
          <ListSubheader>Owner Actions</ListSubheader>
          <DrawerListItem
            label="Edit List"
            to={`/dashboard/lists/${listId}/edit`}
            icon={<EditIcon />}
            handleCloseDrawer={this.handleCloseDrawer}
          />
          <ListItem
            button
            onClick={() => {
              this.setState({ resetDialogOpen: true });
            }}>
            <ListItemIcon>
              <GearIcon />
            </ListItemIcon>
            <ListItemText primary="Weekly Reset" />
          </ListItem>
          <WeeklyResetDialog
            listId={this.props.match.params.listId}
            open={this.state.resetDialogOpen}
            onClose={() => {
              this.setState({ resetDialogOpen: false });
            }}
            onConfirm={() => this.setState({ resetDialogOpen: false })}
          />
        </List>
      </React.Fragment>
    );
  }

  renderDrawerListItem(label, to, icon) {
    const { classes } = this.props;
    return (
      <ListItem
        exact
        activeClassName={classes.activeLink}
        button
        component={NavLink}
        to={to}
        onClick={this.handleCloseDrawer}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
    );
  }

  renderContent = () => {
    const { listId } = this.props.match.params;
    const { pendingRequestCount, requestsLoading } = this.state;
    return (
      <React.Fragment>
        <List>
          <DrawerListItem
            label="My Lists"
            to="/dashboard"
            icon={<BackIcon />}
            handleCloseDrawer={this.handleCloseDrawer}
          />
        </List>
        <Divider />
        <List>
          <ListSubheader>Resources</ListSubheader>
          <DrawerListItem
            label="Requests"
            to={`/dashboard/lists/${listId}`}
            handleCloseDrawer={this.handleCloseDrawer}
            icon={
              requestsLoading || pendingRequestCount === 0 ? (
                <RequestIcon />
              ) : (
                <Badge badgeContent={pendingRequestCount} color="secondary">
                  <RequestIcon />
                </Badge>
              )
            }
          />
          <DrawerListItem
            label="Stores"
            to={`/dashboard/lists/${listId}/stores`}
            icon={<StoreIcon />}
            handleCloseDrawer={this.handleCloseDrawer}
          />
          <DrawerListItem
            label="Members"
            to={`/dashboard/lists/${listId}/members`}
            icon={<GroupIcon />}
            handleCloseDrawer={this.handleCloseDrawer}
          />
          <Divider />
        </List>

        {this.renderEditButton()}
        <List>
          <ListSubheader>My Profile</ListSubheader>
          <DrawerListItem
            label="Edit Profile"
            to={`/dashboard/user`}
            handleCloseDrawer={this.handleCloseDrawer}
            icon={<MemberIcon />}
          />
        </List>
      </React.Fragment>
    );
  };

  render() {
    const { classes, match, history, setSnackbarContent, user } = this.props;
    const { isShopping, list, loading, mobileOpen, requests } = this.state;
    const shoppingProgressValue = renderTotalPercentComplete(requests);
    return (
      <div className={classes.root}>
        <DashboardNav
          title={loading ? <CircularProgress /> : list.name}
          shoppingProgressValue={shoppingProgressValue}
          setSnackbarContent={setSnackbarContent}
          handleDrawerToggle={this.handleDrawerToggle}
          isShopping={isShopping}
          user={user}
          match={match}
          history={history}
        />
        <ListDrawer
          handleDrawerToggle={this.handleDrawerToggle}
          renderContent={this.renderContent}
          mobileOpen={mobileOpen}
        />
        <main className={classes.content}>
          <ToolbarMixin />

          {loading ? (
            <CircularProgress />
          ) : (
            <Switch>
              <Route
                path="/dashboard/lists/:listId/edit"
                render={props => (
                  <ListsEdit
                    list={list}
                    user={user}
                    {...props}
                    {...this.props}
                  />
                )}
              />
              <Route
                path="/dashboard/lists/:listId/stores/:storeId/edit"
                render={props => (
                  <StoresEdit
                    list={list}
                    user={user}
                    {...props}
                    setSnackbarContent={setSnackbarContent}
                  />
                )}
              />
              <Route
                path="/dashboard/lists/:listId/stores/new"
                render={props => (
                  <StoresNew
                    list={list}
                    user={user}
                    {...props}
                    setSnackbarContent={setSnackbarContent}
                  />
                )}
              />
              <Route
                path="/dashboard/lists/:listId/stores"
                render={props => (
                  <StoresList user={user} {...props} {...this.props} />
                )}
              />
              <Route
                path="/dashboard/lists/:listId/members"
                render={props => (
                  <MembersList user={user} {...props} {...this.props} />
                )}
              />
              <Route
                path="/dashboard/lists/:listId/requests/:requestId/edit"
                render={props => (
                  <RequestsEdit
                    list={list}
                    user={user}
                    {...props}
                    setSnackbarContent={setSnackbarContent}
                  />
                )}
              />
              <Route
                path="/dashboard/lists/:listId/requests/new"
                render={props => (
                  <RequestsNew
                    list={list}
                    user={user}
                    {...props}
                    setSnackbarContent={setSnackbarContent}
                  />
                )}
              />
              <Route
                path="/dashboard/lists/:listId"
                render={({ history, match }) => (
                  <ListsShow
                    shoppingProgressValue={shoppingProgressValue}
                    handleShoppingToggle={this.handleShoppingToggle}
                    isShopping={isShopping}
                    list={list}
                    user={user}
                    requests={this.state.requests}
                    history={history}
                    match={match}
                  />
                )}
              />
            </Switch>
          )}
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(ListsContainer);
