import React from 'react';
import RequestsFormFields from './form-fields';
import { updateRequest, deleteRequestDoc } from '../../helpers/lists-refs';
import { getFirebaseTimestamp } from '../../../base';
import Typography from '@material-ui/core/Typography';
import FormContainer from '../../shared/form-container';
import ConfirmationDialogRaw from '../../shared/confirmation-dialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

class RequestsEdit extends React.Component {
  state = { open: false };
  handleDelete = () => {
    const { listId, requestId } = this.props.match.params;
    deleteRequestDoc(listId, requestId).then(() => {
      this.props.setSnackbarContent('Request has been deleted');
      this.props.history.push(`/dashboard/lists/${listId}`);
    });
  };

  handleSubmit = (request, resetFormThen) => {
    const { listId, requestId } = this.props.match.params;
    const dateNow = getFirebaseTimestamp();

    let req = { ...request };
    Object.assign(req, {
      cancelledAt: null,
      cancellerId: null,
      cancelled: false,
      obtained: false,
      obtainedAt: null,
      obtainerId: null,
      updatedAt: dateNow,
      archived: false
    });
    updateRequest(listId, requestId, req).then(() => {
      resetFormThen(() => {
        this.props.setSnackbarContent('Updated request successfully');
        this.props.history.push(`/dashboard/lists/${listId}`);
      });
    });
  };

  render() {
    const { match, user } = this.props;
    return (
      <React.Fragment>
        <FormContainer>
          <Typography variant="display1">Edit Request</Typography>
          <RequestsFormFields
            submitLabel="Update Request"
            match={match}
            handleRequestAction={this.handleSubmit}
            user={user}
          />
          <IconButton
            aria-label="Delete"
            onClick={() => this.setState({ open: true })}>
            <DeleteIcon fontSize="large" />
          </IconButton>
          <ConfirmationDialogRaw
            open={this.state.open}
            onClose={() => this.setState({ open: false })}
            onConfirm={this.handleDelete}
          />
        </FormContainer>
      </React.Fragment>
    );
  }
}

export default RequestsEdit;
