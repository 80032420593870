import PropTypes from 'prop-types';
import React from 'react';
import { updateUserDoc } from '../../helpers/lists-refs';
import Typography from '@material-ui/core/Typography';
import Form from '../stores/form';

class UserProfile extends React.Component {
  displayNameInput = React.createRef();

  handleSubmit = (nameObj) => {
    const { uid } = this.props.user;
    const userData = { displayName: nameObj.name };
    updateUserDoc(uid, userData).then(() => {
      this.props.history.push('/dashboard');
    });
  }

  render() {
    const { user } = this.props;
    return (
      <React.Fragment>
        <Typography variant='body1'>
          Your display name only applies to this application, not your Google account.
        </Typography>

        <Form
          name={ user.displayName }
          handleSubmit={ this.handleSubmit }
          submitLabel='Update Display Name'
        />
      </React.Fragment>
    );
  }
}

UserProfile.propTypes = {
  user: PropTypes.shape({
    displayName: PropTypes.string.isRequired
  })
};

export default UserProfile;