import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  emptyMessage: {
    border: '5px dashed #EEEEEE',
    borderRadius: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 5,
    textAlign: 'center'
  }
});

const EmptyMessage = ({ classes, message }) => (
  <div className={ classes.emptyMessage }>
    <Typography variant='headline'>
      { message }
    </Typography>
  </div>
);

EmptyMessage.propTypes = {
  message: PropTypes.string.isRequired
};

export default withStyles(styles)(EmptyMessage);