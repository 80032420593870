import { createMuiTheme } from '@material-ui/core/styles';
import { blue, pink, red } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink,
    error: red
  }
});

export default theme;