import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const styles = theme => ({
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

const AppBarMenuIcon = ({ classes, handleDrawerToggle }) => (
  <IconButton
    className={ classes.navIconHide }
    color='inherit'
    onClick={ handleDrawerToggle }
  >
    <MenuIcon />
  </IconButton>
);

export default withStyles(styles)(AppBarMenuIcon);