import React from 'react';
import { Link } from 'react-router-dom';
import { getListDoc, getUserDoc } from '../../helpers/lists-refs';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import PrimaryButton from '../../shared/primary-button';
import ToolbarMixin from '@material-ui/core/styles';
import { Paper, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PendingRequestBadge from './pending-request-badge';
import Panel from '../../shared/panel';
import Grid from '../../shared/grid';

class ListsIndex extends React.Component {
  state = { lists: [], loading: true, error: null };

  handleUserDoc = userDoc => {
    let user = userDoc.data();
    if (!user || !user.lists) return;
    let listIds = Object.keys(user.lists);
    listIds.forEach(listId => {
      getListDoc(listId).then(listDoc => {
        let { lists } = this.state;
        let list = listDoc.data();
        list.id = listDoc.id;
        lists.push(list);
        this.setState({ lists });
      });
    });
  };

  componentDidMount() {
    const { uid } = this.props.user;
    getUserDoc(uid).then(this.handleUserDoc);
  }

  renderListItems() {
    const { lists } = this.state;
    const lastIndex = lists.length - 1;
    const { uid } = this.props.user;
    return lists.map(({ id, name, ownerId }, index) => {
      return (
        <React.Fragment key={index}>
          <ListItem button component={Link} to={`/dashboard/lists/${id}`}>
            <PendingRequestBadge listId={id} />
            <ListItemText primary={name} />
            {uid === ownerId ? (
              <ListItemSecondaryAction>
                <IconButton component={Link} to={`/dashboard/lists/${id}/edit`}>
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            ) : (
              ''
            )}
          </ListItem>
          {lastIndex !== index ? <Divider /> : ''}
        </React.Fragment>
      );
    });
  }

  renderCustomItem = ({ id, name, ownerId }) => {
    const { uid } = this.props.user;
    return (
      <ListItem button component={Link} to={`/dashboard/lists/${id}`}>
        <PendingRequestBadge listId={id} />
        <ListItemText primary={name} />
        {uid === ownerId ? (
          <ListItemSecondaryAction>
            <IconButton component={Link} to={`/dashboard/lists/${id}/edit`}>
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        ) : (
          ''
        )}
      </ListItem>
    );
  };

  renderLists() {
    const { lists } = this.state;
    if (lists.length === 0) return 'No lists found';
    return (
      <Paper>
        <List>{this.renderListItems()}</List>
      </Paper>
    );
  }

  renderDrawerContent() {
    return (
      <React.Fragment>
        <ToolbarMixin />
        <Divider />
        <List>
          <ListItem button component={Link} to={`/dashboard/lists/new`}>
            <ListItemText primary="Add List" />
          </ListItem>
        </List>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <PrimaryButton
          component={Link}
          to="/dashboard/lists/new"
          label="Create New List"
        />
        <Panel
          emptyMessage="You have no grocery lists"
          items={this.state.lists}
          renderCustomItem={this.renderCustomItem}
        />
      </React.Fragment>
    );
  }
}

export default ListsIndex;
