import React, { Component } from 'react';
import { auth } from './base';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import PrivateRoute from './private-route';
import DashboardContainer from './components/containers/dashboard';
import LoginPage from './components/pages/login';
import NotFoundPage from './components/pages/not-found';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiThemeProvider } from '@material-ui/core/styles';
import StandardTheme from './components/themes/standard';
import CssBaseline from '@material-ui/core/CssBaseline';

class App extends Component {
  state = { authLoading: true, user: null };

  componentDidMount() {
    this.removeListener = auth.onAuthStateChanged(user => {
      if (user) {
        this.setState({ user, authLoading: false });
      } else {
        this.setState({ user: null, authLoading: false });
      }
    });
  }

  componentWillUnmount() {
    this.removeListener();
  }

  render() {
    const { authLoading, user } = this.state;
    if (authLoading) return <CircularProgress />;
    return (
      <React.Fragment>
        <CssBaseline />
        <MuiThemeProvider theme={StandardTheme}>
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path="/"
                render={props => <LoginPage user={user} {...props} />}
              />
              <Route
                path="/login"
                render={props => <LoginPage user={user} {...props} />}
              />
              <PrivateRoute
                user={user}
                path="/dashboard"
                render={props => <DashboardContainer {...props} />}
              />
              <Route component={NotFoundPage} />
            </Switch>
          </BrowserRouter>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

export default App;
