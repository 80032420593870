import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ListContainer from './lists';
import { listenToUserDoc } from '../helpers/lists-refs';
import OtherContainer from './other';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '../shared/snackbar';

class DashboardContainer extends React.Component {
  state = { user: null, loading: true, isSnackbarOpen: false, snackbarMessage: '' }

  componentDidMount() {
    const { uid } = this.props.user;
    this.removeListener = listenToUserDoc(uid, (snapshot) => {
      const user = { ...snapshot.data() };
      user.uid = uid;
      this.setState({ user, loading: false });
    }, () => {
    });
  }

  componentWillUnmount() {
    this.removeListener();
  }

  handleSnackbarClose = (ev, reason) => {
    this.setState({
      isSnackbarOpen: false,
      snackbarMessage: ''
    });
  }

  setSnackbarContent = (message) => {
    this.setState({
      isSnackbarOpen: true,
      snackbarMessage: message
    });
  }

  render() {
    const { user, loading , snackbarMessage, isSnackbarOpen } = this.state;
    if (loading) return <CircularProgress />;

    return (
      <React.Fragment>
        <Snackbar
          handleClose={ this.handleSnackbarClose }
          message={ snackbarMessage }
          open={ isSnackbarOpen }
        />
        <Switch>
          <Route exact path='/dashboard' render={ (props) => (
            <OtherContainer user={ user } { ...props } navTitle='My Lists' />
          )} />
          <Route exact path='/dashboard/lists/new' render={ (props) => (
            <OtherContainer
              setSnackbarContent={ this.setSnackbarContent }
              user={ user }
              { ...props }
              navTitle='My Lists'
            />
          )} />
          <Route path='/dashboard/user' render={ (props) => (
            <OtherContainer user={ user } { ...props } navTitle='My Profile' />
          )} />
          <Route path='/dashboard/lists/:listId' render={ (props) => (
            <ListContainer
              setSnackbarContent={ this.setSnackbarContent }
              user={ user }
              { ...props }
            />
          )} />
        </Switch>
      </React.Fragment>
    )
  }
}

export default DashboardContainer;
