import React from 'react';
import Panel from '../../shared/panel';

const renderPanels = (requestMap, storeMap, props) => {
  return Object.keys(requestMap)
    .sort()
    .map((storeId, index) => {
      return (
        <Panel
          defaultExpanded={index === 0}
          expandable={true}
          key={index}
          title={storeMap[storeId] || 'Store Not Specified'}
          items={requestMap[storeId]}
          renderCustomItem={props.renderCustomItem}
        />
      );
    });
};

const generateRequestMap = requests => {
  let requestMap = {};
  requests.forEach(request => {
    let { storeId } = request;
    if (requestMap[storeId] === undefined) requestMap[storeId] = [];
    requestMap[storeId].push(request);
  });
  return requestMap;
};

const ShoppingList = props => {
  let { requests, storesMap } = props;
  const requestMap = generateRequestMap(requests);

  return renderPanels(requestMap, storesMap, props);
};

export default ShoppingList;
