import React from 'react';
import { addStoreDoc, updateListDoc } from '../../helpers/lists-refs';
import Typography from '@material-ui/core/Typography';
import Form from './form';

class StoresNew extends React.Component {
  handleSubmit = ({ name, isDefault }) => {
    const { listId } = this.props.match.params;

    addStoreDoc(listId, { name }).then(storeDoc => {
      if (isDefault) {
        console.log('This store is new DEFAULT. Update list.');
        updateListDoc(listId, { defaultStoreId: storeDoc.id });
      } else {
        console.log('Not set to default, so nothing to do.');
      }
      this.props.setSnackbarContent('Successfully added store');
      this.props.history.push(`/dashboard/lists/${listId}/stores`);
    });
  };

  handleChange = ev => {
    this.setState({ name: ev.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="headline">New Store</Typography>
        <Form
          name=""
          isDefault={false}
          submitLabel="Create Store"
          handleSubmit={this.handleSubmit}
        />
      </React.Fragment>
    );
  }
}

export default StoresNew;
