import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import StoreSelector from '../shared/store-selector';
import { updateRequest } from '../helpers/lists-refs';

export default class FormDialog extends React.Component {
  state = { storeId: this.props.request.storeId };

  handleClose = () => {
    this.props.handleClose();
    // this.setState({ open: false });
  };

  handleSubmit = () => {
    // update the storeId of this request
    const { request, listId } = this.props;
    const { storeId } = this.state;

    updateRequest(listId, request.id, { storeId }).then(() => {
      this.props.handleClose();
    });
  };

  handleChange = ev => {
    this.setState({ storeId: ev.target.value });
  };

  render() {
    const { listId, handleClose, request, open } = this.props;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Assign Store</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose a new store to assign to this request.
          </DialogContentText>
          <StoreSelector
            storeId={this.state.storeId}
            listId={listId}
            request={request}
            handleChange={this.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleSubmit} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
