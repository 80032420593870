import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { db } from '../../base';
import {
  getListRecurringRequests,
  getListRequestsWithStatus,
  getRequests,
  updateRequest,
  addRequestToList
} from '../helpers/lists-refs';

const options = [
  'None',
  'Atria',
  'Callisto',
  'Dione',
  'Ganymede',
  'Hangouts Call',
  'Luna',
  'Oberon',
  'Phobos',
  'Pyxis',
  'Sedna',
  'Titania',
  'Triton',
  'Umbriel'
];

class ConfirmationDialogRaw extends React.Component {
  handleCancel = () => {
    this.props.onClose();
  };

  handleConfirm = () => {
    // Get all of the obtained and cancelled requests
    this.props.onClose();
    const { listId } = this.props;
    let toBeArchived = [];
    /*
    getListRequests(listId).then(snap => {
      snap.forEach(doc => {
        let reqId = doc.id;
        updateRequest(listId, reqId, { archived: false }).then(doc => {
          console.log('doc: ', doc);
        });
      });
      console.log(toBeArchived);
    });
  };
  */
    getListRequestsWithStatus(listId, 'obtained').then(res => {
      console.log('res: ', res);
      res.forEach(doc => {
        let el = doc.data();
        el.id = doc.id;
        toBeArchived.push(el);
      });
      getListRequestsWithStatus(listId, 'cancelled').then(res => {
        console.log('res2: ', res);
        res.forEach(doc => {
          let el = doc.data();
          el.id = doc.id;
          toBeArchived.push(el);
        });

        // Step 1: Archive the obtained and cancelled requests
        console.log('toBeArchived: ', toBeArchived);
        toBeArchived.map(request => {
          updateRequest(listId, request.id, { archived: true }).then(() => {
            console.log(`Archived ${request.id} | ${request.name}`);
          });
        });

        getListRecurringRequests(listId).then(res => {
          res.forEach(doc => {
            // Is this item recurring? If so, make a copy.
            let request = doc.data();
            if (request.isRecurring) {
              request.obtained = false;
              request.obtainedAt = null;
              request.obtainerId = null;
              request.cancelled = false;
              request.cancelledAt = null;
              request.cancellerId = null;
              request.archived = false;
              updateRequest(listId, doc.id, request, () => {
                console.log('Finished updating recurring request in list.');
              });
            }
          });
        });
      });
    });
  };

  render() {
    const { open, ...other } = this.props;

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        open={open}
        {...other}>
        <DialogTitle id="confirmation-dialog-title">Weekly Reset</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will remove the obtained and cancelled items from the shopping
            list.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func,
  value: PropTypes.string
};

export default ConfirmationDialogRaw;
