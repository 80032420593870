import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ render: Component, user, ...rest }) => (
  <Route
    { ...rest }
    render={ (props) => (
      user ?
        <Component user={ user } { ...props } /> :
        <Redirect to='/login' />
    )}
  />
)

export default PrivateRoute;