import Hidden from '@material-ui/core/Hidden';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Input from '@material-ui/core/Input';
import React from 'react';
import { MenuItem } from '@material-ui/core';
import { Loading } from './loading';
import { listenToStoresCollection } from '../helpers/lists-refs';

class StoreSelector extends React.Component {
  state = { loading: true, stores: [] };

  renderStoreOptions(useMenuItem) {
    const { stores } = this.state;
    if (stores.length === 0) return;
    let options = [
      useMenuItem ? (
        <MenuItem key="0" value="">
          Not Specified
        </MenuItem>
      ) : (
        <option key="0" value="">
          Not Specified
        </option>
      )
    ];
    stores.forEach(({ name, id }) => {
      useMenuItem
        ? options.push(
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          )
        : options.push(
            <option key={id} value={id}>
              {name}
            </option>
          );
    });
    return options;
  }

  handleStores = snapshot => {
    let stores = [];
    snapshot.forEach(storeDoc => {
      let store = storeDoc.data();
      store.id = storeDoc.id;
      stores.push(store);
    });
    this.setState({ stores, loading: false });
  };

  componentDidMount() {
    const { listId } = this.props;
    this.requestListener = listenToStoresCollection(listId, this.handleStores);
  }

  componentWillUnmount() {
    this.requestListener();
  }

  render() {
    const { loading } = this.state;
    const { handleChange, storeId } = this.props;

    if (loading) return <Loading />;

    return (
      <>
        <Hidden smDown>
          <Select
            fullWidth
            inputProps={{
              name: 'storeId',
              id: 'storeId'
            }}
            value={storeId}
            onChange={handleChange}>
            {this.renderStoreOptions(true)}
          </Select>
        </Hidden>
        <Hidden mdUp>
          <NativeSelect
            fullWidth
            value={storeId}
            onChange={handleChange}
            input={<Input name="storeId" id="storeId" />}>
            {this.renderStoreOptions()}
          </NativeSelect>
        </Hidden>
      </>
    );
  }
}

export default StoreSelector;
