import React from 'react';
import {
  getListDoc,
  getRequestDoc,
  listenToStoresCollection
} from '../../helpers/lists-refs';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import NativeSelect from '@material-ui/core/NativeSelect';
import Select from '@material-ui/core/Select';
import Hidden from '@material-ui/core/Hidden';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { MenuItem, Typography } from '@material-ui/core';
import PrimaryButton from '../../shared/primary-button';

const newRequest = () => ({
  name: '',
  substituteAllowed: true,
  isRecurring: false,
  storeId: '',
  description: ''
});

class RequestsFormFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestLoading: true,
      storesLoading: true,
      listLoading: true,
      stores: [],
      defaultStoreId: '',
      request: newRequest()
    };
  }

  handleChange = (name, isCheckbox, ev) => {
    let { request } = this.state;
    request[name] = isCheckbox ? ev.target.checked : ev.target.value;
    this.setState({ request });
  };

  handleSubmit = ev => {
    ev.preventDefault();
    let request = { ...this.state.request };
    this.props.handleRequestAction(request, cb => {
      cb();
    });
  };

  handleStores = snapshot => {
    let stores = [];
    snapshot.forEach(storeDoc => {
      let store = storeDoc.data();
      store.id = storeDoc.id;
      stores.push(store);
    });
    this.setState({ stores, storesLoading: false });
  };

  componentDidMount() {
    const { listId, requestId } = this.props.match.params;

    getListDoc(listId).then(doc => {
      let req = this.state.request;
      req.storeId = doc.data().defaultStoreId;
      this.setState({
        defaultStoreId: req.defaultStoreId,
        request: req,
        listLoading: false
      });
    });

    // Populate store selector
    this.requestListener = listenToStoresCollection(listId, this.handleStores);

    // Get request if edit, otherwise just return
    if (!requestId) return this.setState({ requestLoading: false });
    getRequestDoc(listId, requestId).then(doc => {
      this.setState({ request: doc.data(), requestLoading: false });
    });
  }

  componentWillUnmount() {
    this.requestListener();
  }

  renderStoreOptions(useMenuItem) {
    const { stores } = this.state;
    if (stores.length === 0) return;
    let options = [
      useMenuItem ? (
        <MenuItem key="0" value="">
          Not Specified
        </MenuItem>
      ) : (
        <option key="0" value="">
          Not Specified
        </option>
      )
    ];
    stores.forEach(({ name, id }) => {
      useMenuItem
        ? options.push(
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          )
        : options.push(
            <option key={id} value={id}>
              {name}
            </option>
          );
    });
    return options;
  }

  render() {
    const { storesLoading, requestLoading, listLoading } = this.state;
    if (storesLoading || requestLoading || listLoading)
      return 'Loading form...';

    const { request, defaultStoreId } = this.state;
    const {
      creatorId,
      name,
      substituteAllowed,
      description,
      isRecurring,
      storeId
    } = request;
    const { submitLabel, user } = this.props;

    const { uid } = user;
    let isDisabled = creatorId !== undefined && creatorId !== uid;

    return (
      <form onSubmit={this.handleSubmit}>
        {isDisabled ? (
          <Typography variant="subheading">
            Note: Since you are not the original creator, you can only change
            the store.
          </Typography>
        ) : (
          ''
        )}
        <TextField
          autoFocus
          disabled={isDisabled}
          margin="normal"
          autoComplete="off"
          fullWidth
          label="Name"
          name="name"
          placeholder="Product you want"
          value={name}
          onChange={this.handleChange.bind(this, 'name', false)}
        />
        <FormGroup row>
          <FormControlLabel
            label="Substitute Allowed"
            control={
              <Switch
                disabled={isDisabled}
                name="substituteAllowed"
                checked={substituteAllowed}
                onChange={this.handleChange.bind(
                  this,
                  'substituteAllowed',
                  true
                )}
              />
            }
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            label="Recurring Weekly"
            control={
              <Switch
                disabled={isDisabled}
                name="isRecurring"
                checked={isRecurring}
                onChange={this.handleChange.bind(this, 'isRecurring', true)}
              />
            }
          />
        </FormGroup>
        <FormGroup row>
          <InputLabel htmlFor="storeId">Suggested Store</InputLabel>
          <Hidden smDown>
            <Select
              fullWidth
              inputProps={{
                name: 'storeId',
                id: 'storeId'
              }}
              value={storeId !== '' ? storeId : defaultStoreId}
              onChange={this.handleChange.bind(this, 'storeId', false)}>
              {this.renderStoreOptions(true)}
            </Select>
          </Hidden>
          <Hidden mdUp>
            <NativeSelect
              fullWidth
              value={storeId !== '' ? storeId : defaultStoreId}
              onChange={this.handleChange.bind(this, 'storeId', false)}
              input={<Input name="storeId" id="storeId" />}>
              {this.renderStoreOptions()}
            </NativeSelect>
          </Hidden>
        </FormGroup>
        <TextField
          disabled={isDisabled}
          fullWidth
          margin="normal"
          label="Further Instructions"
          placeholder="e.g. Quantity, brand, type, etc."
          value={description}
          onChange={this.handleChange.bind(this, 'description', false)}
        />
        <PrimaryButton type="submit" variant="contained" label={submitLabel} />
      </form>
    );
  }
}

export default RequestsFormFields;
