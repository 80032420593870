import PropTypes from 'prop-types';
import React from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  success: {
    backgroundColor: '#26A69A'
  }
});

class Notifications extends React.Component {
  renderContent() {
    const { classes, message } = this.props;
    return (
      <SnackbarContent
        className={ classes['success'] }
        message={ <span>{ message }</span> }
      />
    );
  }

  render() {
    const { classes, duration, handleClose, open } = this.props;
    return (
      <Snackbar
        classes={ classes.something }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={ open }
        onClose={ handleClose }
        autoHideDuration={ duration }
      >
        { this.renderContent() }
      </Snackbar>
    );
  }
}

Notifications.propTypes = {
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  duration: PropTypes.number
};

Notifications.defaultProps = {
  duration: 3000 // ms
};

export default withStyles(styles)(Notifications);