import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

const styles = {
  card: {
    maxWidth: 345
  },
  media: {
    height: 140
  }
};

const Grid = ({ classes, items, emptyMessage, renderCustomItem }) => {
  return (
    <GridList cellHeight={180} className={classes.gridList}>
      {items.map(({ id, name, ownerId }, index) => (
        <GridListTile key={index}>
          <div
            style={{
              height: '300px',
              width: '100%',
              background: 'linear-gradient(green, blue)'
            }}
          />
          <GridListTileBar
            title={name}
            subtitle={<span>Hello world</span>}
            actionIcon={
              <IconButton className={classes.icon}>
                <InfoIcon />
              </IconButton>
            }
          />
        </GridListTile>
      ))}
    </GridList>
  );
};

export default withStyles(styles)(Grid);
