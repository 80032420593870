import React, { useState } from 'react';
import { auth, provider } from '../../base';
import * as UserAuth from '../helpers/user-auth';
import { Redirect } from 'react-router-dom';
import { getUserDoc } from '../helpers/lists-refs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import cloudinary from 'cloudinary-core';
import googleButton from '../../images/btn_google_signin_dark_normal_web@2x.png';
import { animated, useSpring } from 'react-spring';
const cloudinaryCore = cloudinary.Cloudinary.new({ cloud_name: 'mfarmer' });

const Container = styled.div`
  padding: 0 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(${props => props.bg});
  background-size: cover;
`;

const GoogleButton = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 30px 0;
  img {
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 191px;
  }
`;

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    textAlign: 'center',
    width: '100%',
    margin: '0 auto',
    maxWidth: '500px'
  },
  button: {
    margin: theme.spacing.unit * 2,
    marginLeft: 0
  }
});

const handleGoogleSignIn = props =>
  auth
    .signInWithPopup(provider)
    .then(({ user }) => {
      handleUserSetup(user, props);
    })
    .catch(err => console.log('err: ', err));

const handleUserSetup = (user, props) => {
  getUserDoc(user.uid).then(doc => {
    let userData = doc.data();

    if (doc.exists) {
      let destinationUrl = '/dashboard';
      if (userData.lists && Object.keys(userData.lists).length === 1) {
        destinationUrl = `/dashboard/lists/${Object.keys(userData.lists)[0]}`;
      }
      console.log(user);
      UserAuth.updateUser(
        user,
        () => {
          props.history.push(destinationUrl);
        },
        () => {}
      );
    } else {
      UserAuth.createUser(user, () => {
        props.history.push('/dashboard/user');
      });
    }
  });
};

const LoginPage = props => {
  const { classes, user } = props;
  if (user) return <Redirect to="/dashboard" />;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailSignIn = () => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(({ user }) => {
        handleUserSetup(user, this.props);
      })
      .catch(err => {
        console.log('error: ', err);
      });
  };

  const springProps = useSpring({
    opacity: 1,
    transform: 'scale(1)',
    from: { opacity: 0, transform: 'scale(0.8)' }
  });
  return (
    <Container
      bg={cloudinaryCore.url('the-bagel-cafe', {
        auto: 'format',
        secure: true,
        effect: 'blur:1000'
      })}>
      <animated.div style={springProps}>
        <Paper className={classes.root}>
          <Typography
            gutterBottom
            component="h1"
            variant="h3"
            style={{ fontFamily: 'Baloo Chettan' }}>
            Fetching
          </Typography>
          <Typography
            gutterBottom
            component="subtitle"
            variant="subheading"
            style={{ fontFamily: 'Roboto' }}>
            A shared, realtime shopping list management application for
            families.
          </Typography>

          <form
            onSubmit={ev => {
              ev.preventDefault();
              handleEmailSignIn();
            }}>
            <TextField
              id="email"
              label="email"
              value={email}
              onChange={ev => setEmail(ev.target.value)}
              margin="normal"
              fullWidth
            />
            <TextField
              id="password"
              label="password"
              type="password"
              value={password}
              margin="normal"
              onChange={ev => setPassword(ev.target.value)}
              fullWidth
            />
            <Button
              type="submit"
              className={classes.button}
              variant="contained"
              fullWidth>
              Login
            </Button>
          </form>

          <Typography gutterButtom variant="h6">
            - or -
          </Typography>

          <GoogleButton onClick={handleGoogleSignIn.bind(this, props)}>
            <img src={googleButton} alt="Sign in with Google" />
          </GoogleButton>
        </Paper>
      </animated.div>
    </Container>
  );
};

export default withStyles(styles)(LoginPage);
