import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
  activeLink: {
    backgroundColor: grey[200]
  }
});

const DrawerListItem = ({ classes, label, to, icon, handleCloseDrawer }) => (
  <ListItem
    exact
    activeClassName={ classes.activeLink }
    button
    component={ NavLink }
    to={ to }
    onClick={ handleCloseDrawer }
  >
    <ListItemIcon>
      { icon }
    </ListItemIcon>
    <ListItemText primary={ label } />
  </ListItem>
)

DrawerListItem.propTypes = {
  classes: PropTypes.shape({
    activeLink: PropTypes.string.isRequired
  }).isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.any,
  handleCloseDrawer: PropTypes.func.isRequired
};

export default withStyles(styles)(DrawerListItem);