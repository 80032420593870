import { db } from '../../base';

export const getListDoc = listId => {
  return new Promise((resolve, reject) => {
    db.collection('lists')
      .doc(listId)
      .get()
      .then(listDoc => resolve(listDoc))
      .catch(err => reject(err));
  });
};

export const updateListDoc = (listId, listData) => {
  return new Promise((resolve, reject) => {
    db.collection('lists')
      .doc(listId)
      .update(listData)
      .then(resolve)
      .catch(reject);
  });
};

export const getListRequests = listId => {
  return new Promise((resolve, reject) => {
    db.collection('lists')
      .doc(listId)
      .collection('requests')
      .get()
      .then(resolve)
      .catch(reject);
  });
};

export const getListRecurringRequests = listId => {
  return new Promise((resolve, reject) => {
    db.collection('lists')
      .doc(listId)
      .collection('requests')
      .where('isRecurring', '==', true)
      .get()
      .then(resolve)
      .catch(reject);
  });
};

export const getListRequestsWithStatus = (listId, status = 'obtained') => {
  return new Promise((resolve, reject) => {
    db.collection('lists')
      .doc(listId)
      .collection('requests')
      .where(status, '==', true)
      .where('archived', '==', false)
      .get()
      .then(resolve)
      .catch(reject);
  });
};

export const getRequestDoc = (listId, reqId) => {
  return new Promise((resolve, reject) => {
    db.collection('lists')
      .doc(listId)
      .collection('requests')
      .doc(reqId)
      .get()
      .then(resolve)
      .catch(reject);
  });
};

export const deleteRequestDoc = (listId, reqId) => {
  return new Promise((resolve, reject) => {
    db.collection('lists')
      .doc(listId)
      .collection('requests')
      .doc(reqId)
      .delete()
      .then(resolve)
      .catch(reject);
  });
};

export const listenToRequestsCollection = (listId, success, failure) => {
  let ref = db
    .collection('lists')
    .doc(listId)
    .collection('requests')
    .orderBy('createdAt', 'desc');
  ref.onSnapshot(success, failure);
};

export const listenToStoresCollection = (listId, success, failure) => {
  return db
    .collection('lists')
    .doc(listId)
    .collection('stores')
    .orderBy('name')
    .onSnapshot(success, failure);
};

export const addStoreDoc = (listId, storeData) => {
  return new Promise((resolve, reject) => {
    db.collection('lists')
      .doc(listId)
      .collection('stores')
      .add(storeData)
      .then(resolve)
      .catch(reject);
  });
};

export const deleteStoreDoc = (listId, storeId) => {
  return new Promise((resolve, reject) => {
    db.collection('lists')
      .doc(listId)
      .collection('stores')
      .doc(storeId)
      .delete()
      .then(resolve)
      .catch(reject);
  });
};

export const updateStoreDoc = (listId, storeId, storeData) => {
  return new Promise((resolve, reject) => {
    db.collection('lists')
      .doc(listId)
      .collection('stores')
      .doc(storeId)
      .update(storeData)
      .then(resolve)
      .catch(reject);
  });
};

export const getStoreDoc = (listId, storeId) => {
  return new Promise((resolve, reject) => {
    db.collection('lists')
      .doc(listId)
      .collection('stores')
      .doc(storeId)
      .get()
      .then(resolve)
      .catch(reject);
  });
};

export const addRequestToList = (request, listId, success, failure) => {
  db.collection('lists')
    .doc(listId)
    .collection('requests')
    .add(request)
    .then(success, failure);
};

export const listenToRequestDoc = (listId, reqId, success, failure) => {
  return db
    .collection('lists')
    .doc(listId)
    .collection('requests')
    .doc(reqId)
    .onSnapshot(success, failure);
};

export const listenToListDoc = (listId, success, failure) => {
  return db
    .collection('lists')
    .doc(listId)
    .onSnapshot(success, failure);
};

export const updateRequest = (listId, reqId, reqData) => {
  return new Promise((resolve, reject) => {
    db.collection('lists')
      .doc(listId)
      .collection('requests')
      .doc(reqId)
      .update(reqData)
      .then(resolve)
      .catch(reject);
  });
};

export const addUserDoc = (userId, userData) => {
  return new Promise((resolve, reject) => {
    db.collection('users')
      .doc(userId)
      .set(userData)
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
};

export const getUserDoc = userId => {
  return new Promise((resolve, reject) => {
    db.collection('users')
      .doc(userId)
      .get()
      .then(resolve)
      .catch(reject);
  });
};

export const listenToUserDoc = (userId, success, failure) => {
  return db
    .collection('users')
    .doc(userId)
    .onSnapshot(
      snapshot => {
        success(snapshot);
      },
      err => {
        failure(err);
      }
    );
};

export const updateUserDoc = (userId, userData) => {
  return new Promise((resolve, reject) => {
    db.collection('users')
      .doc(userId)
      .update(userData)
      .then(resolve)
      .catch(reject);
  });
};

export const addListDoc = (listData, uid) => {
  return new Promise((resolve, reject) => {
    let batch = db.batch();
    let listsRef = db.collection('lists').doc();
    let newListId = listsRef.id;

    batch.set(listsRef, listData);

    let userRef = db.collection('users').doc(uid);
    let memberListData = {};
    memberListData[`lists.${newListId}`] = true;
    batch.update(userRef, memberListData);
    batch
      .commit()
      .then(() => {
        resolve(newListId);
      })
      .catch(reject);
  });
};
