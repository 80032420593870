import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';

const drawerWidth  = 240;
const styles = theme => ({
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      position: 'fixed'
    }
  }
});

class ListDrawer extends React.Component {
  render() {
    const { classes, handleDrawerToggle, mobileOpen } = this.props;
    return (
      <React.Fragment>
        <Hidden mdUp>
          <Drawer
            variant='temporary'
            anchor='left'
            open={ mobileOpen }
            onClose={ handleDrawerToggle }
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            { this.props.renderContent() }
          </Drawer>
        </Hidden>
        <Hidden smDown implementation='css'>
          <Drawer
            variant='permanent'
            anchor='left'
            open
            classes={{ paper: classes.drawerPaper }}
          >
            { this.props.renderContent() }
          </Drawer>
        </Hidden>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ListDrawer);