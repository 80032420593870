import React from 'react';
import Panel from '../../shared/panel';
import { getUserDoc, listenToListDoc } from '../../helpers/lists-refs';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PrimaryButton from '../../shared/primary-button';

class MembersList extends React.Component {
  state = { members: [], loading: true }

  componentDidMount() {
    const { listId } = this.props.match.params;

    this.memberListener = listenToListDoc(listId, (doc) => { 
      let memberIds = Object.keys(doc.data().members);
      this.setState({ members: [] });
      
      memberIds.forEach((memberId) => {
        getUserDoc(memberId).then((doc) => {
          let { members } = this.state;
          let member = doc.data();
          member.id = doc.id;
          members.push(member)
          this.setState({ members, loading: false });
        });
      });
    });
  }

  componentWillUnmount() {
    this.memberListener();
  }

  renderAvatar = ({ displayName, photoURL }) => {
    return <Avatar alt={ displayName } src={ photoURL } />;
  }

  renderDetails = ({ displayName }) => {
    return displayName;
  }

  renderCustomItem = ({ displayName, photoURL }) => {
    return (
      <ListItem>
        <Avatar alt={ displayName } src={ photoURL } />
        <ListItemText primary={ displayName } />
      </ListItem>
    );
  }

  render() {
    const { loading, members } = this.state;
    if (loading) return 'Loading members...';

    return (
      <React.Fragment>
        <PrimaryButton label='Invite User' />
        <Panel
          title='List Members'
          items={ members }
          renderCustomItem={ this.renderCustomItem }
        />
      </React.Fragment>
    );
  }
}

export default MembersList;