import React from 'react';
import { Badge, Hidden, NativeSelect, Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  tabs: {
    marginTop: '30px',
    marginBottom: '15px'
  },
  padding: {
    padding: `0 ${ theme.spacing.unit * 2 }px`
  },
  nativeSelect: {
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2
  }
});

const renderTab = (tab, tabIndex, props) => {
  let badgeContent = props.filteredRequests(tabIndex).length;
  const { label, color } = tab;
  return (
    <Tab
      key={ label }
      label={
        badgeContent > 0 ?
        <Badge
          className={ props.classes.padding }
          badgeContent={ badgeContent }
          color={ color }
        >
          { label }
        </Badge> :
        label
      }
    />
  );
}

const renderSelectOptions = (props) => (
  props.tabs.map(({ label }, i) => {
    return (
      <option key={ i } value={ i }>
        { label } ({ props.filteredRequests(i).length })
      </option>
    );
  })
);

const renderTabs = (props) => (
  props.tabs.map((tab, i) => {
    return renderTab(tab, i, props);
  })
);

const Filters = (props) => (
  <React.Fragment>
    <Hidden xsDown>
      <Tabs
        className={ props.classes.tabs }
        centered
        value={ props.selectedTab }
        onChange={ props.handleTabChange }
      >
        { renderTabs(props) }
      </Tabs>
      </Hidden>
      <Hidden smUp>
      <NativeSelect
        className={ props.classes.nativeSelect }
        fullWidth
        value={ props.selectedTab }
        onChange={ props.handleSelectChange }
      >
        { renderSelectOptions(props) }
      </NativeSelect>
    </Hidden>
  </React.Fragment>
)

export default withStyles(styles)(Filters);