import React from 'react';
import ListForm from '../stores/form';
import { addListDoc } from '../../helpers/lists-refs';
import { getFirebaseTimestamp } from '../../../base';
import Typography from '@material-ui/core/Typography';

const handleSubmit = (props, listData) => {
  const { uid } = props.user;

  let memberData = {};
  memberData[uid] = true;

  let list = { ...listData };
  let dateNow = getFirebaseTimestamp();
  Object.assign(list, {
    ownerId: uid,
    members: memberData,
    createdAt: dateNow,
    updatedAt: dateNow
  });

  addListDoc(list, uid).then((newListId) => {
    props.setSnackbarContent('Successfully added list');
    props.history.push(`/dashboard/lists/${ newListId }`);
  });
}

const ListsNew = (props) => (
  <React.Fragment>
    <Typography variant='headline'>New List</Typography>
    <ListForm
      handleSubmit={ handleSubmit.bind(this, props) }
      name=''
      submitLabel='Create List'
    />
  </React.Fragment>
)

export default ListsNew;