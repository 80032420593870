import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ListsNew from '../pages/lists/new';
import ListsIndex from '../pages/lists/index';
import UserProfile from '../pages/users/profile';
import { withStyles } from '@material-ui/core/styles';
import DashboardNav from '../shared/dashboard-nav';
import Drawer from '../shared/drawer';
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';
import ToolbarMixin from '../shared/toolbar-mixin';
import { NavLink } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import grey from '@material-ui/core/colors/grey';
import DrawerListItem from '../shared/drawer-list-item';

const styles = theme => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.backgroundColor,
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.up('md')]: {
      marginLeft: 240
    }
  },
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  activeLink: {
    backgroundColor: grey[200]
  }
});

class OtherContainer extends React.Component {
  state = { drawerIsOpen: false };

  handleDrawerToggle = () => {
    const { drawerIsOpen } = this.state;
    this.setState({ drawerIsOpen: !drawerIsOpen });
  };

  renderDrawerListItem(label, to, icon) {
    const { classes } = this.props;
    return (
      <ListItem
        button
        exact
        component={NavLink}
        activeClassName={classes.activeLink}
        to={to}
        onClick={this.handleDrawerToggle}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
    );
  }

  renderDrawerContent = () => {
    return (
      <React.Fragment>
        <ToolbarMixin />
        <Divider />
        <List>
          <DrawerListItem
            label="My Lists"
            to="/dashboard"
            icon={<ListIcon />}
            handleCloseDrawer={this.handleDrawerToggle}
          />
          <Divider />
          <DrawerListItem
            label="Add List"
            to="/dashboard/lists/new"
            icon={<AddIcon />}
            handleCloseDrawer={this.handleDrawerToggle}
          />
        </List>
      </React.Fragment>
    );
  };

  render() {
    const {
      classes,
      match,
      history,
      navTitle,
      setSnackbarContent,
      user
    } = this.props;
    const { drawerIsOpen } = this.state;
    return (
      <div className={classes.root}>
        <DashboardNav
          title={navTitle}
          user={user}
          match={match}
          history={history}
          handleDrawerToggle={this.handleDrawerToggle}
        />
        <Drawer
          handleDrawerToggle={this.handleDrawerToggle}
          renderContent={this.renderDrawerContent}
          mobileOpen={drawerIsOpen}
        />
        <main className={classes.content}>
          <ToolbarMixin />
          <Switch>
            <Route
              exact
              path="/dashboard"
              render={props => <ListsIndex user={user} {...props} />}
            />
            <Route
              exact
              path="/dashboard/lists/new"
              render={props => (
                <ListsNew
                  user={user}
                  {...props}
                  setSnackbarContent={setSnackbarContent}
                />
              )}
            />
            <Route
              path="/dashboard/user"
              render={props => <UserProfile user={user} {...props} />}
            />
          </Switch>
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(OtherContainer);
